.svg-wrapper {
  position: relative;
  display: inline-block;
}

.icon-base {
  position: relative;
  z-index: 2;
}

.svg-container {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.car-icon {
  transition: transform 0.2s ease;
}

.car-icon {
  transition: all 0.2s ease; /* This will make the changes smooth */
}

.car-icon[data-dragover='true'] {
  filter: brightness(150%) drop-shadow(16px 16px 20px #424242);
  transform: scale(1.4);
}

.car-icon[data-drop='true'] {
  transform: scale(1.6);
}

.car-icon:hover {
  transform: scale(1.2);
}

.car-icon path {
  filter: drop-shadow(-1px -1px 0px #ffffff) drop-shadow(1px -1px 0px #ffffff)
    drop-shadow(1px 1px 0px #ffffff) drop-shadow(-1px 1px 0px #ffffff);
}

/* .icon-base {
    filter: 
    drop-shadow(-1px -1px 0px #ffffff) 
    drop-shadow(2px -1px 0px #ffffff) 
    drop-shadow(2px 2px 0px #ffffff)
    drop-shadow(-1px 2px 0px #ffffff);
} */

.label {
  border-radius: 3px;
  padding: 2px 4px;
  text-align: center;
}

.map-container {
  position: relative;
  width: 100vw;
  height: 100vh;
}

#map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f0f0f0;
}

/* Essential Leaflet container styles */
.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.leaflet-tile-pane {
  z-index: 2;
}

.leaflet-overlay-pane {
  z-index: 3;
}

.leaflet-marker-pane {
  z-index: 4;
}

.leaflet-popup-pane {
  z-index: 5;
}

/* animations.css */

/* Smooth transitions for markers */
.leaflet-marker-icon {
  transition: transform 0.3s ease-out;
}

/* Hover effect for markers */
.leaflet-marker-icon:hover {
  z-index: 1000 !important;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
}

/* Tooltip styling */
.label {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* SVG Car icon animation on hover */
svg.car-icon {
  transition: transform 0.2s ease-out, fill 0.2s ease;
}

svg.car-icon:hover {
  transform: scale(1.1);
}

/* Animation for drag and drop events */
[data-dragover='true'] {
  transform: scale(1.2);
  filter: brightness(1.3);
}

[data-drop='true'] {
  animation: pulse 0.3s ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

/* Loading indicator for map */
.map-loading {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 1000;
  font-size: 12px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.map-loading::after {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  border: 2px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Add these styles to your map.css file */

/* Tooltip styling for all markers */
.leaflet-tooltip {
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 4px 8px;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
  z-index: 1000;
}

/* Remove the arrow from tooltips */
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  display: none;
}

/* Specific styling for car markers */
.leaflet-tooltip.label {
  transform: translateY(16px); /* Move tooltip down below the icon */
  background: white;
  color: #333;
  font-weight: normal;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  width: auto;
  text-align: center;
}

/* Style for the car marker when hovering */
.car-marker {
  transition: transform 0.2s ease;
}

.car-marker:hover {
  z-index: 1001 !important;
}

/* Driver tooltip styling */
.leaflet-tooltip.driver-tooltip {
  background-color: white;
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  padding: 4px 8px;
  margin-top: 8px;
  text-align: center;
  width: auto;
  min-width: 80px;
  white-space: nowrap;
  pointer-events: none;
  z-index: 1000;
}

/* Remove the tooltip arrow */
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  display: none !important;
}

/* Make sure the tooltip doesn't overlap the icon */
.leaflet-div-icon {
  background: transparent;
  border: none;
}

/* Ensure tooltip text is readable */
.leaflet-tooltip {
  font-size: 12px;
  line-height: 1.4;
}

/* Map toast notifications */
.map-toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 20px;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  max-width: 80%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease, transform 0.3s ease;
  text-align: center;
}

.map-toast.show {
  opacity: 1;
  transform: translateX(-50%) translateY(-10px);
}

.map-toast-info {
  background-color: #2196f3;
}

.map-toast-success {
  background-color: #4caf50;
}

.map-toast-warning {
  background-color: #ff9800;
}

.map-toast-error {
  background-color: #f44336;
}

/* Additional drag active state for map container */
.map-container.drag-active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(33, 150, 243, 0.05);
  border: 2px dashed rgba(33, 150, 243, 0.5);
  pointer-events: none;
  z-index: 1000;
}

/* Error toast */
.error-toast {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 16px;
  background-color: #f44336;
  color: white;
  border-radius: 4px;
  z-index: 1001;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
