/* PULSING ANIMATION */
/* Shows a pulsing circle under the element the color of the parent */
.pulse {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.pulse::before,
.pulse::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    animation: pulsing 2.5s linear infinite;
    background: currentColor;
    opacity: 0.2;
}

.pulse::after {
    animation: pulsing1 2.5s linear infinite;
}

@keyframes pulsing {
    0% {
        opacity: 0.2;
        transform: scale(1);
    }

    70% {
        opacity: 0.1;
        transform: scale(1.8);
    }

    80%,
    100% {
        opacity: 0;
        transform: scale(2);
    }
}

@keyframes pulsing1 {
    0% {
        opacity: 0.2;
        transform: scale(1);
    }

    70% {
        opacity: 0.1;
        transform: scale(1.3);
    }

    80%,
    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}